<template>
  <div class="charge-info">
    <div class="charge-info-line">
      【参考】TOKYUポイント付与対象総額：<TvosJpy :value="chargeInfo.tokyuPointTargetTotal" />
    </div>
  </div>
</template>

<script>
import TvosJpy from '../DataItem/TvosJpy.vue'

export default {
  components: { TvosJpy },
  props: {
    chargeInfo: Object
  },
}
</script>

<style lang="scss" scoped>
.charge-info {
  text-align: right;
}
</style>
