import gql from 'graphql-tag'

export const BILLING_STATEMENT_LIST = `query (
  $bookingGroupId: Int! #予約グループID（予約IDではない）。 booking.bookingGroupId 
  $bookingIds: [Int!]
) {
  bookingGroupListChargesV2 (bookingGroupId: $bookingGroupId, bookingIds: $bookingIds) {
    charges {
      id
      stayDate
      bookingId
      booking {
        id
        room {
          name #部屋名
        }
      }
      type {name}
      typeId
      note
      subject
      price #単価これがNot nullなら単位は¥（日本円）、 
      taxIncluded # true:税込み、False:税抜き
      priceWithSalesTax
      salesTax #税額
      taxRate #税率
      spPoint # 単価これがNot nullなら単位はSP SP・FP・TVPのみポイントなので画面上のポイント列にアイコンが出る。ポイントの場合はチェックボックスは出さない
      fpPoint # 単価これがNot nullなら単位はFP
      tvpPoint # 単価これがNot nullなら単位はTVP
      quantity #数量 小計は quantity * priceWitSalesTax
      bookingInvoiceId # インボイスID #これがNot nullならチェックボックスは出さない
      externalPaymentMethodId #外部支払の場合の方法ID これがNot nullならチェックボックスは出さない
      priceChangeAllowed #ここがfalse の場合、価格変更はできないようにする
    }
    totalByTaxRate {
      taxRate
      total
      salesTax
    }
    chargeInfo {
      tokyuPointTargetTotal
    }
  }
  
  bookingInvoiceList (bookingGroupId: $bookingGroupId, bookingIds: $bookingIds) {
    id #インボイスID
    finalizedAt #発行登録した日時 NULLの場合のみ 削除が可能 https://overflow.io/s/R78SRYB6?node=7b56c3f1
    createdAt # 作成した日時
    addresseeName
    receiptFor #但し書き
    finalizedAt #発行登録日時、Nullは発行登録以前
    pdfFileUrl #ダウンロード可能なPDFファイル
    defaultEmailTo # 発送先メールアドレス
    representativeEmailTo # 代表者メールアドレス
    payments { #支払い方法
      id
      bookingInvoiceId
      bookingPaymentMethod { id name category }
      paymentAmount
    }
  }
}`

export const BOOKING_PAYMENT_METHOD_LIST = `query {
  enumBookingPaymentMethodList {
    id
    category # ここでグループ化して、ここの値を精算方法に入れる
    name # 選択されたカテゴリに応じてここの値を選択肢として表示
  }
}`

export const CHARGE_REMARKS = `query ($bookingGroupId: Int!) {
  bookingGroup (id: $bookingGroupId) {
    createdSubMember {
      subMemberTypeId
    }
    chargeRemarks
    registerdCCSubjects
    onsiteSubjects
    defaultPaymentType
  }
}`

export const UPDATE_POINT_ADDDITIONAL_CHARGE = `
  mutation ($chargeId: Int!, $newPoint: Int!) {
    updatePointAdditionalCharge(chargeId: $chargeId, newPoint: $newPoint) {
      id
    }
  }
`

export const BOOKING_ADDITIONAL_CHARGE_TYPE_LIST = gql`query {
    bookingAdditionalChargeTypeList {
      id
      name
    }
  }
`

export const BOOKING_ADDITIONAL_CHARGE_SUBJECT_LIST = gql`query ($typeId: Int) {
    bookingAdditionalChargeSubjectList (typeId: $typeId) {
      id
      name
    }
  }
`
// 価格の初期値を読み込むAPI。科目名称選択を行った後に実行して価格の初期値、価格変更許可を取得する
export const CHARGE_DEFAULT = gql`query (
    $subjectId: Int!,
    $stayDate: DateString!
) {
  chargeDefault (subjectId: $subjectId, stayDate: $stayDate) {
      defaultPrice # Int Not nullならその値を価格の初期値に設定する
      priceChangeAllowed #Boolean! false の場合価格の入力欄をReadonlyにする
    }
  }
`

export const CREATE_ADDDITIONAL_CHARGE = `
  mutation (
    $bookingId: Int!
    $stayDate: DateString!
    $subjectId: Int!
    $note: String!
    $price: Int!
    $quantity: Int!
  ) {
    createAdditionalCharge(bookingId: $bookingId, stayDate: $stayDate, subjectId: $subjectId, note: $note, price: $price, quantity: $quantity) {
      id
    }
  }
`
export const UPDATE_ADDDITIONAL_CHARGE = `
  mutation (
    $chargeId: Int!
    $quantity: Int!
    $price: Int!
    $subjectId: Int!
    $note: String
  ) {
    updateAdditionalCharge(chargeId: $chargeId, quantity: $quantity, price: $price, subjectId: $subjectId, note: $note) {
      id
    }
  }
`
export const REMOVE_CHARGE = `
  mutation (
    $chargeId: Int!
  ) {
    deleteAdditionalCharge(chargeId: $chargeId)
  }
`

// create payment methods
export const CREATE_BOOKING_INVOICES = `
  mutation (
    $bookingGroupId: Int!
    $chargeIds: [Int!]!
    $payments: [CreateBookingInvoicePaymentInput!]!
  ) {
    createBookingInvoices(createInvoiceData: {
      bookingGroupId: $bookingGroupId
      chargeIds: $chargeIds,
      payments: $payments
    }) {
      id
      addresseeName
      receiptFor #但し書き
      finalizedAt #発行登録日時、Nullは発行登録以前
      pdfFileUrl #ダウンロード可能なPDFファイル
      defaultEmailTo # 発送先メールアドレス
      representativeEmailTo # 代表者メールアドレス
    }
  }
`

export const FINALIZE_BOOKING_INVOICES = `
  mutation (
    $bookingInvoiceId: Int!
    $invoiceSendToEmails: [String!]!
  ) {
    finalizeBookingInvoices(finalizeInvoiceData: {
      bookingInvoiceId: $bookingInvoiceId
      invoiceSendToEmails: $invoiceSendToEmails,
    }) {
      id
    }
  }
`
export const DELETE_BOOKING_INVOICES = `
  mutation (
    $bookingInvoiceId: Int!
  ) {
    deleteBookingInvoices(deleteInvoiceData: {
      bookingInvoiceId: $bookingInvoiceId
    })
  }
`
export const UPDATE_BOOKING_INVOICES = `
  mutation (
    $bookingInvoiceId: Int!
    $addresseeName: String!
    $receiptFor: String!
  ) {
    updateBookingInvoices(updateInvoiceData: {
      bookingInvoiceId: $bookingInvoiceId
      addresseeName: $addresseeName
      receiptFor: $receiptFor
    }) {
      id
      pdfFileUrl
    }
  }
`

export const DETAIL_BOOKING_INVOICES = `query ($id: Int!) {
  bookingInvoice(id: $id) {
    id
    finalizedAt #発行登録した日時 NULLの場合のみ 削除が可能 https://overflow.io/s/R78SRYB6?node=7b56c3f1
    createdAt # 作成した日時
    addresseeName
    receiptFor #但し書き
    finalizedAt #発行登録日時、Nullは発行登録以前
    pdfFileUrl #ダウンロード可能なPDFファイル
    defaultEmailTo # 発送先メールアドレス
    representativeEmailTo # 代表者メールアドレス
  }
}`
